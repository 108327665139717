import './App.css'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <Grid
          container
          display='flex'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <Grid>
            <img src='./disco2.webp' alt='logo' height={300} />
            <Typography align='center' variant='h5'>
              2022 Wales - Coming soon
            </Typography>
          </Grid>
          <Grid>
            <a
              className='App-link'
              href='./2019italy/index.html'
              rel='noopener noreferrer'
            >
              <img src='./2019italy/images/gary.png' alt='logo' height={300} />
              <Typography align='center' variant='h5'>
                2019 Mini-tastic - Italian Job
              </Typography>
            </a>
          </Grid>
        </Grid>
      </header>
    </div>
  )
}

export default App
